var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h-retribusi'),_c('panel',{staticClass:"panel panel-success",attrs:{"title":"FORM USER"}},[_c('div',{staticClass:"pl-4 py-2 border rounded shadow-sm"},[_c('b-button',{staticClass:"px-4",attrs:{"variant":"primary","to":"/users/users/list"}},[_c('i',{staticClass:"fa fa-arrow-circle-left pr-2"}),_vm._v(" Kembali ")])],1),_c('div',{staticClass:"py-3"},[_c('b-card',{staticClass:"shadow-sm"},[_c('b-form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.store($event)},"keydown":function($event){return _vm.form.onKeydown($event)}}},[_c('input',{attrs:{"type":"hidden","name":"_token"},domProps:{"value":_vm.csrf}}),(_vm.message)?_c('b-alert',{attrs:{"variant":"danger","show":""}},[_vm._v("Data yang anda input tidak valid.")]):_vm._e(),_c('div',{staticClass:"form-group row m-b-4"},[_c('label',{staticClass:"col-md-3 col-form-label font-weight-bold"},[_vm._v("Pegawai "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-md-7"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('v-select',{attrs:{"label":"nama","options":_vm.pegawaiOptions,"reduce":function (nama) { return nama.id; },"placeholder":"Pilih Pegawai"},model:{value:(_vm.form.pegawai_id),callback:function ($$v) {_vm.$set(_vm.form, "pegawai_id", $$v)},expression:"form.pegawai_id"}})],1)]),(_vm.form.errors.has('surat_id'))?_c('em',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(_vm.errors.surat_id[0]))]):_vm._e()])]),_c('div',{staticClass:"form-group row m-b-4"},[_c('label',{staticClass:"col-md-3 col-form-label font-weight-bold"},[_vm._v("Nama "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-md-7"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],class:{
                                            'form-control': true,
                                            'is-invalid': _vm.form.errors.has(
                                                'name'
                                            ),
                                        },attrs:{"type":"text","placeholder":"Masukkan Email"},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "name", $event.target.value)}}})])]),(_vm.form.errors.has('name'))?_c('em',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(_vm.errors.name[0]))]):_vm._e()])]),_c('div',{staticClass:"form-group row m-b-4"},[_c('label',{staticClass:"col-md-3 col-form-label font-weight-bold"},[_vm._v("Email "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-md-7"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],class:{
                                            'form-control': true,
                                            'is-invalid': _vm.form.errors.has(
                                                'email'
                                            ),
                                        },attrs:{"type":"email","placeholder":"Masukkan Email"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}})])]),(_vm.form.errors.has('email'))?_c('em',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(_vm.errors.email[0]))]):_vm._e()])]),_c('div',{staticClass:"form-group row m-b-4"},[_c('label',{staticClass:"col-md-3 col-form-label font-weight-bold"},[_vm._v("Password "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-md-7"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],class:{
                                            'form-control': true,
                                            'is-invalid': _vm.form.errors.has(
                                                'password'
                                            ),
                                        },attrs:{"type":"password","placeholder":"Masukkan Email"},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "password", $event.target.value)}}})])]),(_vm.form.errors.has('password'))?_c('em',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(_vm.errors.password[0]))]):_vm._e()])]),_c('hr'),_c('div',{staticClass:"float-right"},[_c('span',[_c('b-button',{staticClass:"px-4",attrs:{"variant":"primary","type":"submit","squared":"","disabled":_vm.isDisabled}},[(_vm.isDisabled === false)?_c('i',{staticClass:"fa fa-save"}):_vm._e(),(_vm.isDisabled)?_c('span',[_c('b-spinner',{attrs:{"variant":"primary","label":"Loading","small":""}})],1):_vm._e(),_vm._v(" Simpan ")])],1),_c('span',{staticClass:"px-2"},[_c('b-button',{staticClass:"px-4",attrs:{"variant":"secondary","squared":""},on:{"click":_vm.reset}},[_c('i',{staticClass:"fa fa-redo-alt"}),_vm._v(" Reset")])],1)])],1)],1)],1)]),_c('b-modal',{attrs:{"id":"modalLoader","hide-footer":"","hide-header":"","no-close-on-backdrop":"","centered":"","size":"sm"},model:{value:(_vm.showLoader),callback:function ($$v) {_vm.showLoader=$$v},expression:"showLoader"}},[_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"primary","label":"Text Centered"}}),_c('h5',[_vm._v("Harap Menunggu...")]),_c('div',[_vm._v("Data Anda Sedang Di Proses")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }