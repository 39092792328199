<template>
    <div>
        <!-- Title and Logo -->
        <h-retribusi />
        <!-- end Logo and Title -->

        <!-- Panel start here -->
        <panel title="FORM USER" class="panel panel-success">
            <!-- <template slot="header">
                <h6 class="font-weight-bold mr-auto"> FORM INPUT BENTUK BADAN</h6>
            </template> -->
            <div class="pl-4 py-2 border rounded shadow-sm">
                <b-button variant="primary" class="px-4" to="/users/users/list">
                    <i class="fa fa-arrow-circle-left pr-2"></i>
                    Kembali
                </b-button>
            </div>
            <div class="py-3">
                <b-card class="shadow-sm">
                    <b-form
                        @submit.prevent="store"
                        @keydown="form.onKeydown($event)"
                        autocomplete="off"
                    >
                        <input type="hidden" name="_token" :value="csrf" />
                        <b-alert v-if="message" variant="danger" show
                            >Data yang anda input tidak valid.</b-alert
                        >
                        <div class="form-group row m-b-4">
                            <label
                                class="col-md-3 col-form-label font-weight-bold"
                                >Pegawai <span class="text-danger">*</span></label
                            >
                            <div class="col-md-7">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <v-select
                                            label="nama"
                                            :options="pegawaiOptions"
                                            :reduce="(nama) => nama.id"
                                            placeholder="Pilih Pegawai"
                                            v-model="form.pegawai_id"
                                        ></v-select>
                                    </div>
                                </div>
                                <em
                                    v-if="form.errors.has('surat_id')"
                                    class="form-text text-danger"
                                    >{{ errors.surat_id[0] }}</em
                                >
                            </div>
                        </div>
                        <div class="form-group row m-b-4">
                            <label
                                class="col-md-3 col-form-label font-weight-bold"
                                >Nama <span class="text-danger">*</span></label
                            >
                            <div class="col-md-7">
                                <div class="row">
                                    <div class="col-sm-8">
                                        <input
                                            type="text"
                                            :class="{
                                                'form-control': true,
                                                'is-invalid': form.errors.has(
                                                    'name'
                                                ),
                                            }"
                                            v-model="form.name"
                                            placeholder="Masukkan Email"
                                        />
                                    </div>
                                </div>
                                <em
                                    v-if="form.errors.has('name')"
                                    class="form-text text-danger"
                                    >{{ errors.name[0] }}</em
                                >
                            </div>
                        </div>
                        <div class="form-group row m-b-4">
                            <label
                                class="col-md-3 col-form-label font-weight-bold"
                                >Email <span class="text-danger">*</span></label
                            >
                            <div class="col-md-7">
                                <div class="row">
                                    <div class="col-sm-8">
                                        <input
                                            type="email"
                                            :class="{
                                                'form-control': true,
                                                'is-invalid': form.errors.has(
                                                    'email'
                                                ),
                                            }"
                                            v-model="form.email"
                                            placeholder="Masukkan Email"
                                        />
                                    </div>
                                </div>
                                <em
                                    v-if="form.errors.has('email')"
                                    class="form-text text-danger"
                                    >{{ errors.email[0] }}</em
                                >
                            </div>
                        </div>
                        <div class="form-group row m-b-4">
                            <label
                                class="col-md-3 col-form-label font-weight-bold"
                                >Password <span class="text-danger">*</span></label
                            >
                            <div class="col-md-7">
                                <div class="row">
                                    <div class="col-sm-8">
                                        <input
                                            type="password"
                                            :class="{
                                                'form-control': true,
                                                'is-invalid': form.errors.has(
                                                    'password'
                                                ),
                                            }"
                                            v-model="form.password"
                                            placeholder="Masukkan Email"
                                        />
                                    </div>
                                </div>
                                <em
                                    v-if="form.errors.has('password')"
                                    class="form-text text-danger"
                                    >{{ errors.password[0] }}</em
                                >
                            </div>
                        </div>
                        <hr />
                        <div class="float-right">
                            <span>
                                <b-button
                                    variant="primary"
                                    type="submit"
                                    squared
                                    class="px-4"
                                    :disabled="isDisabled"
                                >
                                    <i
                                        class="fa fa-save"
                                        v-if="isDisabled === false"
                                    ></i>
                                    <span v-if="isDisabled"
                                        ><b-spinner
                                            variant="primary"
                                            label="Loading"
                                            small
                                        ></b-spinner
                                    ></span>
                                    Simpan
                                </b-button>
                            </span>
                            <span class="px-2">
                                <b-button
                                    variant="secondary"
                                    squared
                                    class="px-4"
                                    @click="reset"
                                    ><i class="fa fa-redo-alt"></i>
                                    Reset</b-button
                                >
                            </span>
                        </div>
                    </b-form>
                </b-card>
            </div>
        </panel>
        <!-- Panel end here -->
        <b-modal
            v-model="showLoader"
            id="modalLoader"
            hide-footer
            hide-header
            no-close-on-backdrop
            centered
            size="sm"
        >
            <div class="text-center">
                <b-spinner variant="primary" label="Text Centered"></b-spinner>
                <h5>Harap Menunggu...</h5>
                <div>Data Anda Sedang Di Proses</div>
            </div>
        </b-modal>
    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars
import Logo from "@/assets/images/logoSumut.png";
import HRetribusi from "@/components/header/HRetribusi.vue";
import axios from "axios";
import dateMixin from "@/helper/dateFormat.js";
import storeMixin from "@/helper/hapusLocalStore.js";

export default {
    mixins: [dateMixin, storeMixin],
    components: { HRetribusi },
    data() {
        return {
            imageLogo: Logo,
            form: new Form({
                pegawai_id: '',
                name: '',
                email: '',
                passowrd: ''
            }),

            /* // variabel form rekening
            rekening: {
                kodeNama: '',
                kodeRekening: '0'
            }, */
            errors: [],
            message: null,
            // csrf token
            csrf: document
                .querySelector('meta[name="csrf-token"]')
                .getAttribute("content"),
            // select variabel
            pegawaiOptions: [],
            showLoader: false,
            isDisabled: false,
        };
    },
    mounted() {
        this.getPegawai();
    },
    methods: {
        // store data
        store() {
            this.showLoader = true;
            this.isDisabled = true;
            this.form
                .post("/api/transaksi/ssp")
                .then((response) => {
                    if (this.form.successful) {
                        this.showLoader = false;
                        this.$swal({
                            icon: "success",
                            title: "Data berhasil ditambah",
                            type: "success",
                            confirmButtonClass: "btn btn-info",
                            showConfirmButton: true,
                            confirmButtonText: "OKE",
                            showCloseButton: true,
                            timer: 5000,
                        });
                        this.$router.push({ name: "ListSSP" });
                        /*  this.form.reset()
                    this.kodeNama= ''
                    this.message = false */
                    }
                })
                .catch((error) => {
                    this.showLoader = false;
                    if (error.response.status === 422) {
                        this.isDisabled = false;
                        this.errors = error.response.data;
                        this.message = error.response.data.message;
                    } else if (error.response.status === 401) {
                        // helper hapus local storage
                        this.clearAll()
                    } else if (error.response.status === 400) {
                        this.isDisabled = false;
                        this.$swal({
                            icon: "error",
                            title: "Terjadi masalah pada server",
                            type: "error",
                            confirmButtonClass: "btn btn-info",
                            showConfirmButton: true,
                            confirmButtonText: "OKE",
                            showCloseButton: true,
                            timer: 5000,
                        });
                    } else if (error.response.status === 500) {
                        this.isDisabled = false;
                        this.$swal({
                            icon: "error",
                            title: "Terjadi masalah pada server",
                            text: error.response.data.error,
                            confirmButtonClass: "btn btn-info",
                            showConfirmButton: true,
                            confirmButtonText: "OKE",
                            showCloseButton: true,
                            timer: 5000,
                        });
                    }
                });
        },
        // reset
        reset() {
            this.form.reset();
        },
        // data spt
        getPegawai() {
            axios
                .get("/api/data-induk/kepegawaian/pegawai/getoption")
                .then((response) => {
                    const item = response.data.data;
                    this.pegawaiOptions = item;
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        // helper hapus local storage
                        this.clearAll()
                    }
                });
        },
    },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.8s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>
